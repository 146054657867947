import { useMediaQuery } from "@material-ui/core";

export const XSMALL_HEIGHT_BREAKPOINT = 600;
export const XSMALL_HEIGHT_BREAKPOINT_MQ = `@media (max-height: ${XSMALL_HEIGHT_BREAKPOINT}px)`;

/**
 * Detect if the current viewport height is extra small (Under 600px)
 */
export default function useXSmallHeightMediaQuery() {
  return useMediaQuery(`(max-height:${XSMALL_HEIGHT_BREAKPOINT}px)`);
}
