import useIsAuthenticated from "../../../hooks/use-is-authenticated";
import {
  isPodActivationRequired,
  isUserAccountRequiredForPodActivation
} from "../utils/pod-flags";
import usePodActivationAuthorization from "./usePodActivationAuthorization";
import { usePodDetails } from "./usePodDetails";

export default function useUnlockAllowed(podKeyOrSerialNumber?: string) {
  const isAuthenticated = useIsAuthenticated();

  // Load pod details
  const { data: podDetails, isLoading: isLoadingPodDetails } =
    usePodDetails(podKeyOrSerialNumber);
  const isActivationRequired = isPodActivationRequired(podDetails);
  const isUserAccountRequired =
    isUserAccountRequiredForPodActivation(podDetails);

  // Load activation sequence to see if current user still has access (Previously unlocked..)
  const {
    data: isPodActivationAuthorized,
    isLoading: isLoadingActivationAuthorization
  } = usePodActivationAuthorization(
    isActivationRequired && isAuthenticated ? podDetails?.podSerialNumber : null
  );

  const isLoadingUnlockAllowed =
    isLoadingPodDetails || isLoadingActivationAuthorization;

  const isUnlockAllowed = !isUserAccountRequired || isPodActivationAuthorized;

  return { isUnlockAllowed, isLoadingUnlockAllowed };
}
