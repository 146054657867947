import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { SpacingProps, BordersProps } from "@material-ui/system";
import Skeleton from "@material-ui/lab/Skeleton";
import useXSmallHeightMediaQuery from "../../../../hooks/use-xsmall-height-mq";

type Props = SpacingProps & BordersProps;

export default function ActivationPageSkeleton(props: Props) {
  const isXSmallHeight = useXSmallHeightMediaQuery();
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      minHeight={{
        // prettier-ignore
        xs: `calc(var(--100vh, 100vh) - var(--header-height) - ${isXSmallHeight ? '16px' : '48px'})`,
        sm: "0px"
      }}
      {...props}
    >
      <Box>
        <Skeleton width="50%" height="32px" animation="wave" />
        <Box>
          <Skeleton width="100%" height="32px" animation="wave" />
          <Box mt={-0.5}>
            <Skeleton width="60%" height="32px" animation="wave" />
          </Box>
        </Box>
      </Box>
      <Box display={{ xs: "block", sm: "none" }}>
        <Skeleton
          variant="rect"
          width="100%"
          height="36px"
          style={{ borderRadius: "36px" }}
          animation="wave"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <CircularProgress
              size={16}
              color="secondary"
              style={{ visibility: "visible" }}
              disableShrink
            />
          </Box>
        </Skeleton>
      </Box>
    </Box>
  );
}
