export function scrollToTop(top) {
  return new Promise(resolve => {
    window.scroll({
      behavior: `smooth`,
      top
    });
    let timer;
    const isScrollEnd = () => {
      clearTimeout(timer);
      setTimeout(() => {
        window.removeEventListener(`scroll`, isScrollEnd);
        resolve(true);
      }, 1000);
    };
    window.addEventListener(`scroll`, isScrollEnd, false);
  });
}

export function scrollToAnchor(hashAnchor, offsetTop) {
  const targetDom = document.querySelector(hashAnchor);
  if (targetDom) {
    return scrollToTop(targetDom.offsetTop + (offsetTop || 0));
  } else {
    return Promise.resolve();
  }
}
