import { Box, Button, useTheme } from "@material-ui/core";
import React, { useState } from "react";
import ActivationLayout from "../../../components/Layout";
import { RouteComponentProps } from "@reach/router";
import ActivationHeading from "../../../components/ActivationHeading";
import { Link } from "gatsby";
import CheckmarkButton from "../../../components/CheckmarkButton";
import { showIntercom } from "../../../../../services/intercom";
import useXSmallHeightMediaQuery from "../../../../../hooks/use-xsmall-height-mq";
import BasicModal from "../../../../../components/basic-modal";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import useAuth from "../../../../../hooks/use-auth";
import useActivationPath from "../../../hooks/useActivationPath";
import useRequireUnlockAllowed from "../../../hooks/useRequireUnlockAllowed";

const QUESTIONS = [
  "Screen brightness is set to maximum",
  "If you’ve turned on greyscale, turn it off ",
  "If you’re using screen color filters (color blind aids), turn them off"
];

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

export default function ActivationStep5TroubleShootScreen({
  podKeyOrSerialNumber
}: Props) {
  const theme = useTheme();
  const getActivationPath = useActivationPath();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const [isModalOpen, setModalOpen] = useState(false);
  const { user } = useAuth();
  const isUnlockAllowed = useRequireUnlockAllowed(podKeyOrSerialNumber);

  const handleSupportClick = () => {
    showIntercom(user);
  };

  return (
    <ActivationLayout fullHeight showLoading={!isUnlockAllowed}>
      <Box>
        <Box mb={isXSmallHeight ? 1 : 2}>
          <ActivationHeading>Let’s check the basics first.</ActivationHeading>
        </Box>

        <ActivationHeading>
          Please adjust your screen settings so that:
        </ActivationHeading>

        <Box mt={2}>
          {QUESTIONS.map((question, index) => (
            <Box
              display="flex"
              key={question}
              borderBottom={
                index < QUESTIONS.length - 1
                  ? `1px solid ${theme.palette.grey[300]}`
                  : "0px"
              }
              paddingY={isXSmallHeight ? 1 : 2}
            >
              <CheckmarkButton
                checked
                marginRight={1}
                style={{ transform: "translateY(5px)" }}
              />
              <ActivationHeading>{question}</ActivationHeading>
            </Box>
          ))}
        </Box>
      </Box>

      {/* How? button */}
      <Box
        marginTop={2}
        mb={isXSmallHeight ? 0 : 2}
        display="flex"
        justifyContent="center"
      >
        <Button
          variant="text"
          size="small"
          startIcon={<InfoOutlinedIcon style={{ fontSize: "1.8rem" }} />}
          style={{ padding: 0 }}
          onClick={() => {
            setModalOpen(true);
          }}
        >
          How do I change these settings?
        </Button>
      </Box>

      {/* CTA */}
      <Box mt={2}>
        <Box mb={isXSmallHeight ? 1 : 2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to={getActivationPath(`${podKeyOrSerialNumber}/troubleshoot-pod`)}
          >
            {"Screen is all good"}
          </Button>
        </Box>

        <Button variant="contained" fullWidth onClick={handleSupportClick}>
          {"Contact our support team"}
        </Button>
      </Box>

      {/* Modal */}
      <BasicModal open={isModalOpen} onClose={() => setModalOpen(false)}>
        <Box pb={2}>
          <Box mb={isXSmallHeight ? 1 : 2}>
            <ActivationHeading>
              Screen brightness can usually be found in your phone’s Screen
              Settings.
            </ActivationHeading>
          </Box>
          <ActivationHeading>
            Greyscale and screen color filters are used as color blindness aids
            and are usually found in the Accessibility Settings. If you don’t
            know what these are you’ve most likely not switched them on and can
            move on.
          </ActivationHeading>
        </Box>
      </BasicModal>
    </ActivationLayout>
  );
}
