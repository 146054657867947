import { useQuery } from "react-query";
import { getPodDetails } from "../utils/pod-details";

export function usePodDetails(podKeyOrSerialNumber: string) {
  return useQuery(
    ["activation-pod-details", podKeyOrSerialNumber],
    () => getPodDetails(podKeyOrSerialNumber),
    {
      enabled: !!podKeyOrSerialNumber,
      retry: 3
    }
  );
}
