import { Box, Button, useTheme } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import React, { useState } from "react";
import SmallArrowIcon from "../../../../../components/icons/small-arrow";
import useCustomSnackbar from "../../../../../hooks/use-custom-snackbar";
import useTranslate from "../../../../../hooks/use-translate";
import ActivationHeading from "../../../components/ActivationHeading";
import ActivationLayout from "../../../components/Layout";
import { POD_KEYS_COUNT, POD_KEYS_PATTERN } from "../../../constants";
import useActivationPath from "../../../hooks/useActivationPath";
import useRequireUnlockAllowed from "../../../hooks/useRequireUnlockAllowed";
import { PodDetailsType } from "../../../types";
import {
  getPodDetails,
  INVALID_POD_KEY_ERROR
} from "../../../utils/pod-details";
import CustomReactCodeInput from "../../Step1Pod/Step1PodEnterCode/CustomReactCodeInput";
import FindPodKeyModal from "../../Step1Pod/Step1PodEnterCode/FindPodKeyModal";
import KeyIcon from "../../Step1Pod/Step1PodEnterCode/KeyIcon";

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

export default function ActivationStep5TroubleshootReEnterPodKey({
  podKeyOrSerialNumber
}: Props) {
  const t = useTranslate();
  const theme = useTheme();
  const getActivationPath = useActivationPath();
  const snackbar = useCustomSnackbar();
  const isUnlockAllowed = useRequireUnlockAllowed(podKeyOrSerialNumber);

  // Code
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isValidPodKey, setValidPodKey] = useState(false);

  const handleCodeChange = (value: string) => {
    if (error) {
      setError(false);
    }
    if (value.length >= POD_KEYS_COUNT) {
      submitCode(value);
    }
  };

  // When user enters all keys
  const submitCode = async (code: string) => {
    let podInfo: PodDetailsType = null;
    try {
      setLoading(true);
      podInfo = await getPodDetails(code);
      setLoading(false);
      setValidPodKey(true);
    } catch (e) {
      setLoading(false);
      if (e?.message === INVALID_POD_KEY_ERROR) {
        setError(true);
      } else {
        snackbar.showError(t("error.cantConnectToServer"));
      }
      return;
    }
    // Move to next step
    navigate(getActivationPath(`/${podInfo.podKey}/troubleshoot-pod`));
  };

  let keyIconColor = error ? theme.palette.error.main : null;
  if (isValidPodKey) {
    keyIconColor = theme.palette.info.main;
  }

  // UI
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);

  return (
    <ActivationLayout showLoading={!isUnlockAllowed}>
      <Box mb={4}>
        <ActivationHeading variant="h5">
          You got this. Now enter your pod code below:
        </ActivationHeading>
      </Box>

      <Box display="flex" justifyContent="center">
        <KeyIcon fill={keyIconColor} />
      </Box>

      <Box display="flex" justifyContent="center" mt={2}>
        <CustomReactCodeInput
          fields={POD_KEYS_COUNT}
          type="text"
          name="code"
          inputMode="latin"
          forceUppercase
          onChange={handleCodeChange}
          pattern={POD_KEYS_PATTERN}
          inputStyle={{}}
          inputStyleInvalid={{}}
          isValid={!error}
          isValidPodKey={isValidPodKey}
          disabled={loading}
        />
      </Box>

      {/* Error */}
      {error && (
        <Box marginTop={2}>
          <Alert severity="error">
            You’ve entered an invalid code. Please try entering it again.
          </Alert>
        </Box>
      )}

      {/* Where is the code again? */}
      <Box marginTop={2} display="flex" justifyContent="center">
        <Button
          variant="text"
          size="small"
          startIcon={<SmallArrowIcon fontSize="small" />}
          onClick={() => setHelpModalOpen(true)}
        >
          Remind me, where is it again?
        </Button>
      </Box>

      {/* Where is code bottom modal */}
      <FindPodKeyModal
        open={isHelpModalOpen}
        onClose={() => setHelpModalOpen(false)}
      />
    </ActivationLayout>
  );
}
