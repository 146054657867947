import { useEffect } from "react";

function disablePinchZoom(e: TouchEvent) {
  if (e?.touches?.length > 1) {
    e.preventDefault();
  }
}

// Reference: https://dev.to/terrierscript/suspend-pinch-zoom-on-react-hooks-28pe
export default function usePreventTouchMove() {
  useEffect(() => {
    document.addEventListener("touchmove", disablePinchZoom, {
      passive: false
    });
    return () => {
      document.removeEventListener("touchmove", disablePinchZoom);
    };
  }, []);
}
