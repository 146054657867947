import { analyticsTrackEvent } from "../../../services/analytics";
import api from "../../../services/api";
import { PodActivationDataType } from "../types";

export async function getPodActivationAuthorization(
  podSerialNumber: string
): Promise<boolean> {
  const response = await api.get(`/pas/pod/verify/${podSerialNumber}`);

  if (response.ok) {
    return true;
  }

  if (response.status === 401) {
    // Doesnt have access to pod activation
    return false;
  }

  throw new Error("Could not fetch pod activation");
}

export async function getPodActivationSequence(
  podSerialNumber: string
): Promise<PodActivationDataType> {
  const response = await api.get(`/pas/pod/activate/${podSerialNumber}`);
  const json = await response.json();

  if (response.ok) {
    return json as PodActivationDataType;
  }

  if (response.status === 401) {
    // Doesnt have access to pod activation
    return null;
  }

  throw new Error("Could not fetch pod activation");
}

export async function getGuestPodActivationSequence(
  podSerialNumber: string
): Promise<PodActivationDataType> {
  const response = await api.get(`/pas/pod/guest/activate/${podSerialNumber}`);
  const json = await response.json();

  if (response.ok) {
    return json as PodActivationDataType;
  }

  throw new Error("Could not fetch pod activation");
}

export async function logActivationAttempt(
  podSerialNumber: string
): Promise<boolean> {
  const response = await api.post(
    `/pas/user-activation/history/attempt/${podSerialNumber}`,
    { json: { userDevice: window.navigator.userAgent } }
  );
  analyticsTrackEvent("activation.unlock.attempt");
  if (response.ok) {
    return true;
  }
  throw new Error("Could not log activation attempt");
}

export async function logActivationSuccess(
  podSerialNumber: string
): Promise<boolean> {
  const response = await api.post(
    `/pas/user-activation/history/success/${podSerialNumber}`,
    { json: { userDevice: window.navigator.userAgent } }
  );
  analyticsTrackEvent("activation.unlock.success");
  if (response.ok) {
    return true;
  }
  throw new Error("Could not log activation success");
}

export async function saveToMyPods(podSerialNumber: string): Promise<boolean> {
  const response = await api.get(`/pas/pod/save/${podSerialNumber}`);
  return response.ok;
}
