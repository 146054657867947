import { useEffect } from "react";
import useUnlockAllowed from "./useUnlockAllowed";
import useActivationPath from "./useActivationPath";
import { navigate } from "gatsby";

export default function useRequireUnlockAllowed(
  podKeyOrSerialNumber: string
): boolean {
  const getActivationPath = useActivationPath();

  const { isUnlockAllowed, isLoadingUnlockAllowed } =
    useUnlockAllowed(podKeyOrSerialNumber);

  useEffect(() => {
    if (!isUnlockAllowed && !isLoadingUnlockAllowed) {
      navigate(
        getActivationPath(
          podKeyOrSerialNumber ? `${podKeyOrSerialNumber}/` : "/"
        )
      );
    }
  }, [isUnlockAllowed, isLoadingUnlockAllowed]);

  return isUnlockAllowed;
}
