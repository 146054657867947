import { Box, Button, CircularProgress, Typography } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import ProductDetailsOilTable from "../../../../../containers/shop/ProductDetails/components/Overview/OilTable";
import useFeatureFlag from "../../../../../hooks/use-feature-flag";
import useLocalizedLocationPath from "../../../../../hooks/use-localized-location-path";
import useProductQuery from "../../../../../hooks/use-product-query";
import useTranslate from "../../../../../hooks/use-translate";
import ActivationHeading from "../../../components/ActivationHeading";
import ActivationLayout from "../../../components/Layout";
import PodProductCard from "../../../components/PodProductCard";
import { usePodDetails } from "../../../hooks/usePodDetails";
import useRequireUnlockAllowed from "../../../hooks/useRequireUnlockAllowed";

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

export default function ActivationStep5UnlockComplete({
  podKeyOrSerialNumber
}: Props) {
  const t = useTranslate();
  const isShopEnabled = useFeatureFlag("SHOP_DISCOVER");
  const isUnlockAllowed = useRequireUnlockAllowed(podKeyOrSerialNumber);
  const getLocalizedPath = useLocalizedLocationPath();

  // Pod details
  const { data: podDetails } = usePodDetails(podKeyOrSerialNumber);
  const { data: podProduct, isLoading: isLoadingPodProduct } = useProductQuery({
    batchId: podDetails?.batchId
  });
  const podProductVariant = podProduct?.variants?.find(v =>
    v.batchIds.includes(podDetails?.batchId)
  );

  let continueTo = "/shop";
  if (!isShopEnabled) {
    continueTo = "/user/pods/";
  }

  return (
    <ActivationLayout
      showLoading={!isUnlockAllowed || isLoadingPodProduct}
      fullHeight
    >
      <Box mb={{ xs: 2, md: 4 }}>
        <ActivationHeading>
          {t("activation.step5UnlockComplete.title")}
        </ActivationHeading>
      </Box>

      {/* Pod overview (Cover, title + brand, Oil stats table) */}
      <Box display="flex" flexDirection="column" mb={{ xs: 2, md: 4 }}>
        {/* Pod info card */}
        <PodProductCard
          productVariant={podProductVariant}
          podKey={podDetails?.podKey}
        />

        {/* Pod title & brand */}
        <Box textAlign="center" mt={{ xs: 1.5, md: 3 }}>
          <Typography variant="h5" component="span">
            {podProductVariant?.title}
          </Typography>
        </Box>

        {/* Oil stats table */}
        {podProductVariant && (
          <Box mt={{ xs: 1.5, md: 3 }}>
            <ProductDetailsOilTable
              product={podProduct}
              price={false}
              disableVariantsSelector
            />
          </Box>
        )}
      </Box>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        component={Link}
        to={getLocalizedPath(continueTo)}
      >
        {t("continue")}
      </Button>
    </ActivationLayout>
  );
}
