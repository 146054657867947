import React from "react";
import { useLocation } from "@reach/router";
import { Box } from "@material-ui/core";
import MultiStepNavigation from "../../../../components/multistep-navigation";
import { getNavigationSteps, getNavigationIndexFromPathname } from "./utils";
import { useNavigationState } from "../../hooks/useNavigationState";
import useTranslate from "../../../../hooks/use-translate";

export default function ActivationNavigation() {
  const t = useTranslate();
  const { pathname } = useLocation();
  const navigationState = useNavigationState();
  const isOnLastStep = pathname.endsWith("/complete/");

  const navigationIndex = getNavigationIndexFromPathname(
    pathname,
    navigationState
  );

  if (navigationIndex === null || navigationIndex === -1) {
    return null;
  }

  // Dont show navigation during Step1
  if (!navigationIndex && !navigationState?.enteredPodKeyManually) {
    return null;
  }

  return (
    <Box display="flex" flexDirection="column">
      <MultiStepNavigation
        activeStepIndex={navigationIndex}
        steps={getNavigationSteps(navigationState).map(k => t(k))}
        confirmBeforeLeaving={!isOnLastStep}
      />
    </Box>
  );
}
