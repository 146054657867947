import React from "react";
import { Box, useTheme } from "@material-ui/core";

export default function MultistepNavigationMobileProgressbar({
  variant,
  activeStepIndex,
  totalStepsCount
}: {
  variant?: "default" | "express-delivery";
  activeStepIndex: number;
  totalStepsCount: number;
}) {
  const theme = useTheme();

  return (
    <Box
      position="relative"
      bgcolor={"grey.400"}
      width="100%"
      height={{ xs: "3px", sm: "6px" }}
    >
      <Box
        width={`${((activeStepIndex + 1) / totalStepsCount) * 100}%`}
        height={{ xs: "3px", sm: "6px" }}
        bgcolor={
          variant === "express-delivery"
            ? theme.palette.expressDelivery
            : theme.palette.primary.main
        }
        position="absolute"
        top={0}
        left={0}
        style={{
          transition: "width 250ms"
        }}
        zIndex={1001}
      />
    </Box>
  );
}
