import { Container, NoSsr } from "@material-ui/core";
import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import Seo from "../components/seo";
import Activation from "../containers/activation";
import ActivationPageSkeleton from "../containers/activation/components/PageSkeleton";

export default function ActivationPage({
  data
}: {
  data: Queries.ActivationQueryDataQuery;
}) {
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Seo
        seo={data.datoCmsWebPage?.seo}
        seoMetaTags={data.datoCmsWebPage?.seoMetaTags}
      />
      <NoSsr
        fallback={
          <Container maxWidth="sm">
            <ActivationPageSkeleton marginTop={{ xs: 3, sm: 6, md: 10 }} />
          </Container>
        }
      >
        <Activation />
      </NoSsr>
    </>
  );
}

export const query = graphql`
  query ActivationQueryData($locale: String!) {
    datoCmsWebPage(path: { eq: "unlock" }, locale: { eq: $locale }) {
      seo {
        title
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
  }
`;
