import memoize from "lodash/memoize";
import api from "../../../services/api";
import { PodDetailsType } from "../types";

export const INVALID_POD_KEY_ERROR = "Invalid pod key";

export function isPodKeyOrSerialNumber(
  input: string
): "POD_KEY" | "SERIAL_NUMBER" {
  if (input.match(/[A-Z]{8}/)) {
    return "POD_KEY";
  }
  return "SERIAL_NUMBER";
}

/**
 * Fetch pod details by serial number or pod key
 */
export const getPodDetails = memoize(
  async (podKeyOrSerialNumber: string): Promise<PodDetailsType> => {
    const endpoint =
      isPodKeyOrSerialNumber(podKeyOrSerialNumber) === "POD_KEY"
        ? `pas/batch/details/pod-key/${podKeyOrSerialNumber.toUpperCase()}`
        : `pas/batch/details/pod-serial-number/${podKeyOrSerialNumber}`;

    const response = await api.get(endpoint);

    if (response.ok) {
      const json = await response.json();
      return json as PodDetailsType;
    }

    if (response.status === 404 || response.status === 400) {
      throw new Error(INVALID_POD_KEY_ERROR);
    }

    throw new Error("Something went wrong");
  }
);
