import { Box, Button, styled } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import { getCssVh } from "../../../../../helpers/vh-css-variables";
import useTranslate from "../../../../../hooks/use-translate";
import useXSmallHeightMediaQuery from "../../../../../hooks/use-xsmall-height-mq";
import ActivationHeading from "../../../components/ActivationHeading";
import ActivationLayout from "../../../components/Layout";
import useActivationPath from "../../../hooks/useActivationPath";
import useRequireAuthenticated from "../../../hooks/useRequireAuthenticated";
import useRequireUnlockAllowed from "../../../hooks/useRequireUnlockAllowed";

const CustomVideo = styled("video")(({ theme }) => ({
  width: `${240 * 0.4}px`,
  height: `${800 * 0.4}px`,
  "@media (max-height: 600px)": {
    maxHeight: getCssVh(45)
  },
  "@media (max-height: 500px)": {
    maxHeight: getCssVh(40)
  }
}));

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

export default function ActivationStep4ActivationLightHelp({
  podKeyOrSerialNumber
}: Props) {
  const t = useTranslate();
  const getActivationPath = useActivationPath();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const isUnlockAllowed = useRequireUnlockAllowed(podKeyOrSerialNumber);

  return (
    <ActivationLayout showLoading={!isUnlockAllowed} fullHeight>
      <ActivationHeading>
        {t("activation.step4ActivationLightHelp.title")}
      </ActivationHeading>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        mt={2}
        mb={2}
      >
        <CustomVideo
          controls={false}
          preload="auto"
          muted
          playsInline
          autoPlay
          loop
          // @ts-ignore
          disableRemotePlayback={true}
          poster="https://image.mux.com/SVHI9eDUSwJ2utUDroPF5ywr2VwhbjAc/thumbnail.jpg?time=0"
        >
          <source
            src="https://www.datocms-assets.com/15671/1614003017-step3-trouble-1-export-loop-fix.mp4"
            type={"video/mp4"}
          />
        </CustomVideo>
      </Box>

      <Box>
        <Box mb={isXSmallHeight ? 1 : 2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to={getActivationPath(`${podKeyOrSerialNumber}/activation`)}
          >
            {t("activation.step4ActivationLightHelp.cta")}
          </Button>
        </Box>

        <Button
          variant="contained"
          fullWidth
          component={Link}
          to={getActivationPath(
            `${podKeyOrSerialNumber}/activation-help-battery`
          )}
        >
          {t("activation.step4ActivationLightHelp.secondaryCta")}
        </Button>
      </Box>
    </ActivationLayout>
  );
}
