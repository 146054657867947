import { Box, Button, styled } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import { getCssVh } from "../../../../../helpers/vh-css-variables";
import useTranslate from "../../../../../hooks/use-translate";
import useXSmallHeightMediaQuery from "../../../../../hooks/use-xsmall-height-mq";
import ActivationHeading from "../../../components/ActivationHeading";
import ActivationLayout from "../../../components/Layout";
import useActivationPath from "../../../hooks/useActivationPath";
import usePodActivationSequence from "../../../hooks/usePodActivationSequence";
import { usePodDetails } from "../../../hooks/usePodDetails";
import useRequireAuthenticated from "../../../hooks/useRequireAuthenticated";
import useRequireUnlockAllowed from "../../../hooks/useRequireUnlockAllowed";

const CustomVideo = styled("video")(() => ({
  width: `${240 * 0.4}px`,
  height: `${950 * 0.4}px`,
  "@media (max-height: 700px)": {
    maxHeight: getCssVh(50)
  },
  "@media (max-height: 600px)": {
    maxHeight: getCssVh(45)
  },
  "@media (max-height: 500px)": {
    maxHeight: getCssVh(40)
  }
}));

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

export default function ActivationStep4ActivationLight({
  podKeyOrSerialNumber
}: Props) {
  const t = useTranslate();
  const getActivationPath = useActivationPath();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const isUnlockAllowed = useRequireUnlockAllowed(podKeyOrSerialNumber);

  // Pre-fetch pod infomation
  const { data: podDetails } = usePodDetails(podKeyOrSerialNumber);
  usePodActivationSequence(podDetails?.podSerialNumber);

  return (
    <ActivationLayout showLoading={!isUnlockAllowed} fullHeight>
      <Box>
        <ActivationHeading>
          {t("activation.step4ActivationLight.title1")}
        </ActivationHeading>

        <Box mt={1}>
          <ActivationHeading>
            {t("activation.step4ActivationLight.title2")}
          </ActivationHeading>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" mt={1}>
        <CustomVideo
          controls={false}
          preload="auto"
          muted
          playsInline
          autoPlay
          loop
          disableRemotePlayback={true}
          poster="https://image.mux.com/MxgzZNEjIsZtaLumAT5WxwVuQVhbCWBp/thumbnail.jpg?time=0"
        >
          <source
            src="https://www.datocms-assets.com/15671/1614003019-step3-insert-pod-export-loop-fix.mp4"
            type={"video/mp4"}
          />
        </CustomVideo>
      </Box>

      <Box mt={2}>
        <Box mb={isXSmallHeight ? 1 : 2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to={getActivationPath(`${podKeyOrSerialNumber}/activation`)}
          >
            {t("activation.step4ActivationLight.cta")}
          </Button>
        </Box>
        <Box>
          <Button
            variant="contained"
            fullWidth
            component={Link}
            to={getActivationPath(`${podKeyOrSerialNumber}/activation-help`)}
          >
            {t("activation.step4ActivationLight.secondaryCta")}
          </Button>
        </Box>
      </Box>
    </ActivationLayout>
  );
}
