import { PodDetailsType } from "../types";

export function isPodActivationRequired(podDetails: PodDetailsType): boolean {
  return (
    podDetails?.regionFlags?.activationRequired === undefined || // Fallback to true if missing
    podDetails?.regionFlags?.activationRequired
  );
}

export function isUserAccountRequiredForPodActivation(
  podDetails: PodDetailsType
): boolean {
  return (
    podDetails?.regionFlags?.accountRequired === undefined || // Fallback to true if missing
    podDetails?.regionFlags?.accountRequired
  );
}
