import { styled, Theme } from "@material-ui/core";
import ReactCodeInput from "react-code-input";

const CustomReactCodeInput = styled(ReactCodeInput)(
  ({ theme, isValidPodKey }: { theme: Theme; isValidPodKey?: boolean }) => ({
    maxWidth: "232px",
    "& > input": {
      textAlign: "center",
      fontWeight: 600,
      fontSize: "3rem",
      border: isValidPodKey
        ? `2px solid ${theme.palette.info.main}`
        : `1px solid ${theme.palette.grey[300]}`,
      outline: 0,
      width: "50px",
      height: "62px",
      padding: "0px",
      marginRight: "8px",
      marginBottom: "8px",
      borderRadius: "4px",
      fontFamily: "'Roboto Mono', monospace",
      boxShadow: "none",
      overflow: "visible",
      "&:nth-child(4n+0)": {
        marginRight: 0
      },
      "&:focus": {
        border: !isValidPodKey
          ? `2px solid ${theme.palette.grey[600]}`
          : `2px solid ${theme.palette.info.main}`
      },
      "&:disabled": {
        backgroundColor: `${theme.palette.grey[50]} !important`
      },
      '&[data-valid="false"]': {
        border: `2px solid ${theme.palette.error.main}`
      }
    }
  })
);

export default CustomReactCodeInput;
