import { addTrailingSlash } from "../../../helpers/path";
import useLocalizedLocationPath from "../../../hooks/use-localized-location-path";
import { ACTIVATION_BASE_URL } from "../constants";

export default function useActivationPath() {
  const getLocalizedPath = useLocalizedLocationPath();
  return (path: string) =>
    getLocalizedPath(
      `${ACTIVATION_BASE_URL}${path[0] === "/" ? "" : "/"}${addTrailingSlash(
        path
      )}`
    );
}
