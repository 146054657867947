import React from "react";
import { Redirect } from "@reach/router";
import { ACTIVATION_BASE_URL } from "./constants";
import Step2Auth from "./containers/Step2Auth";
import AnimatedRouter from "../../components/animations/AnimatedRouter";
import ActivationNavigation from "./components/Navigation";
import Step1FindCode from "./containers/Step1Pod/Step1FindCode";
import Step1PodEnterCode from "./containers/Step1Pod/Step1PodEnterCode";
import Step1PodInfo from "./containers/Step1Pod/Step1PodInfo";
import Step4ActivationLight from "./containers/Step4Activation/Step4ActivationLight";
import Step4Activation from "./containers/Step4Activation/Step4Activation";
import Step5ConfirmationQuestion from "./containers/Step5Confirmation/Step5UnlockConfirmationQuestion";
import Step5TroubleShoot from "./containers/Step5Confirmation/Step5Troubleshoot";
import Step5TroubleShootScreen from "./containers/Step5Confirmation/Step5TroubleshootScreen";
import Step5TroubleShootBattery from "./containers/Step5Confirmation/Step5TroubleshootBattery";
import Step5TroubleShootPodKey from "./containers/Step5Confirmation/Step5TroubleshootPodKey";
import Step4ActivationLightHelp from "./containers/Step4Activation/Step4ActivationLightHelp";
import Step4ActivationLightHelpBattery from "./containers/Step4Activation/Step4ActivationHelpBattery";
import Step4ActivationWhy from "./containers/Step4Activation/Step4ActivationWhy";
import Step5TroubleshootReEnterPodKey from "./containers/Step5Confirmation/Step5TroubleshootReEnterPodKey";
import Step5UnlockComplete from "./containers/Step5Confirmation/Step5UnlockComplete";
import NavigationStateProvider from "./providers/navigation-state-provider";
import usePreventTouchMove from "./hooks/usePreventTouchMove";
import useLocalizedLocationPath from "../../hooks/use-localized-location-path";
import useFeatureFlag from "../../hooks/use-feature-flag";
import UnavailableInYourRegion from "./components/UnavailableInYourRegion";

export default function Activation() {
  const getLocalizedPath = useLocalizedLocationPath();
  const isUnlockAppEnabled = useFeatureFlag("UNLOCK_APP");

  usePreventTouchMove(); // Prevent mobile device pinch zoom during activation

  if (!isUnlockAppEnabled) {
    return <UnavailableInYourRegion />;
  }

  return (
    <NavigationStateProvider>
      <ActivationNavigation />

      <AnimatedRouter basepath={getLocalizedPath(ACTIVATION_BASE_URL)}>
        <Step1FindCode path="/" />
        <Step1PodEnterCode path="/enter-code" />
        <Step1PodInfo path="/:podKeyOrSerialNumber" />
        <Step2Auth path="/:podKeyOrSerialNumber/register" />
        <Step2Auth path="/:podKeyOrSerialNumber/sign-in" />
        <Step4ActivationLight path="/:podKeyOrSerialNumber/activation-light" />
        <Step4ActivationLightHelp path="/:podKeyOrSerialNumber/activation-help" />
        <Step4ActivationLightHelpBattery path="/:podKeyOrSerialNumber/activation-help-battery" />
        <Step4Activation path="/:podKeyOrSerialNumber/activation" />
        <Step4ActivationWhy path="/:podKeyOrSerialNumber/activation-why" />
        <Step5ConfirmationQuestion path="/:podKeyOrSerialNumber/confirmation" />
        <Step5TroubleShoot path="/:podKeyOrSerialNumber/troubleshoot" />
        <Step5TroubleShootScreen path="/:podKeyOrSerialNumber/troubleshoot-screen" />
        <Step5TroubleShootBattery path="/:podKeyOrSerialNumber/troubleshoot-battery" />
        <Step5TroubleShootPodKey path="/:podKeyOrSerialNumber/troubleshoot-pod" />
        <Step5TroubleshootReEnterPodKey path="/:podKeyOrSerialNumber/troubleshoot-reenter-pod" />
        <Step5UnlockComplete path="/:podKeyOrSerialNumber/complete" />
        <Redirect
          from="/*"
          to={`${getLocalizedPath(ACTIVATION_BASE_URL)}/`}
          noThrow
        />
      </AnimatedRouter>
    </NavigationStateProvider>
  );
}
