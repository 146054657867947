import { getPodActivationAuthorization } from "../utils/pod-activation";
import { useQuery } from "react-query";

export default function usePodActivationAuthorization(podSerialNumber: string) {
  return useQuery(
    ["activation-pod-authorization", podSerialNumber],
    () => getPodActivationAuthorization(podSerialNumber),
    {
      enabled: !!podSerialNumber,
      cacheTime: 0,
      staleTime: 0,
      retry: 3
    }
  );
}
