import React from "react";

export default function KeyIcon({
  style,
  fill
}: {
  style?: any;
  fill: string;
}) {
  return (
    <svg
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <mask
        id="path-1-outside-1"
        maskUnits="userSpaceOnUse"
        x="2.2168"
        y="8.24316"
        width="25"
        height="13"
        fill="black"
      >
        <rect fill="white" x="2.2168" y="8.24316" width="25" height="13" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.4777 13.2559C11.9592 11.5136 10.3452 10.2432 8.43457 10.2432C6.10516 10.2432 4.2168 12.1315 4.2168 14.4609C4.2168 16.7904 6.10516 18.6787 8.43457 18.6787C10.3452 18.6787 11.9592 17.4083 12.4777 15.666H19.2803V18.6486C19.2803 18.6652 19.2938 18.6787 19.3104 18.6787H21.6603C21.677 18.6787 21.6904 18.6652 21.6904 18.6486V15.666L24.673 15.666C24.6896 15.666 24.7031 15.6525 24.7031 15.6359V13.286C24.7031 13.2693 24.6897 13.2559 24.673 13.2559L12.4777 13.2559Z"
        />
      </mask>
      <path
        d="M12.4777 13.2559L10.7452 13.7715L11.1297 15.0635H12.4777V13.2559ZM12.4777 15.666V13.8584H11.1297L10.7452 15.1504L12.4777 15.666ZM19.2803 15.666H21.0879V13.8584H19.2803V15.666ZM21.6904 15.666V13.8584L19.8828 13.8584V15.666H21.6904ZM24.673 15.666V13.8584V13.8584V15.666ZM24.673 13.2559V15.0635V13.2559ZM8.43457 12.0508C9.52371 12.0508 10.4485 12.7746 10.7452 13.7715L14.2102 12.7402C13.4698 10.2526 11.1668 8.43555 8.43457 8.43555V12.0508ZM6.02442 14.4609C6.02442 13.1298 7.10348 12.0508 8.43457 12.0508V8.43555C5.10684 8.43555 2.40918 11.1332 2.40918 14.4609H6.02442ZM8.43457 16.8711C7.10348 16.8711 6.02442 15.792 6.02442 14.4609H2.40918C2.40918 17.7887 5.10684 20.4863 8.43457 20.4863V16.8711ZM10.7452 15.1504C10.4485 16.1472 9.52371 16.8711 8.43457 16.8711V20.4863C11.1668 20.4863 13.4698 18.6693 14.2102 16.1816L10.7452 15.1504ZM12.4777 17.4736H19.2803V13.8584H12.4777V17.4736ZM17.4727 15.666V18.6486H21.0879V15.666H17.4727ZM17.4727 18.6486C17.4727 19.6635 18.2955 20.4863 19.3104 20.4863V16.8711C20.2921 16.8711 21.0879 17.6669 21.0879 18.6486H17.4727ZM19.3104 20.4863H21.6603V16.8711H19.3104V20.4863ZM21.6603 20.4863C22.6752 20.4863 23.4981 19.6636 23.4981 18.6486H19.8828C19.8828 17.6669 20.6787 16.8711 21.6603 16.8711V20.4863ZM23.4981 18.6486V15.666H19.8828V18.6486H23.4981ZM21.6904 17.4736L24.673 17.4736L24.673 13.8584L21.6904 13.8584L21.6904 17.4736ZM24.673 17.4736C25.688 17.4736 26.5108 16.6508 26.5108 15.6359H22.8955C22.8955 14.6542 23.6913 13.8584 24.673 13.8584V17.4736ZM26.5108 15.6359V13.286H22.8955V15.6359H26.5108ZM26.5108 13.286C26.5108 12.271 25.688 11.4482 24.673 11.4482V15.0635C23.6913 15.0635 22.8955 14.2677 22.8955 13.286H26.5108ZM24.673 11.4482L12.4777 11.4482V15.0635L24.673 15.0635L24.673 11.4482Z"
        fill={fill || "#ACA9A3"}
        mask="url(#path-1-outside-1)"
      />
      <path
        d="M8.43652 12.6533C9.43484 12.6533 10.2441 13.4626 10.2441 14.4609C10.2441 15.4593 9.43484 16.2686 8.43652 16.2686C7.4382 16.2686 6.62891 15.4593 6.62891 14.4609C6.62891 13.4626 7.4382 12.6533 8.43652 12.6533Z"
        fill={fill || "#ACA9A3"}
      />
    </svg>
  );
}
