import { Box, Button } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import useTranslate from "../../../../../hooks/use-translate";
import ActivationHeading from "../../../components/ActivationHeading";
import ActivationLayout from "../../../components/Layout";
import useActivationPath from "../../../hooks/useActivationPath";

type Props = RouteComponentProps<Record<string, unknown>>;

export default function ActivationStep1FindCode({}: Props) {
  const t = useTranslate();
  const getActivationPath = useActivationPath();
  return (
    <ActivationLayout
      justifyContent="space-between"
      minHeight={{
        xs: `calc(var(--100vh, 100vh) - var(--header-height))`,
        sm: 0
      }}
    >
      <Box>
        <ActivationHeading>
          {t("activation.step1FindCode.title1")}
        </ActivationHeading>
        <Box mt={1}>
          <ActivationHeading>
            {t("activation.step1FindCode.title2")}
          </ActivationHeading>
        </Box>
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        mt={{ xs: 2, sm: 4 }}
        mb={{ xs: 2, sm: 4 }}
      >
        <video
          controls={false}
          preload="auto"
          muted
          playsInline
          autoPlay
          style={{
            width: `${300 * 0.4}px`,
            height: `${500 * 0.4}px`
          }}
          disableRemotePlayback={true}
          poster="https://image.mux.com/Qz6T00Jqhk7owUvcPRyeV9DYAJIDc6KbD/thumbnail.jpg?time=0"
        >
          <source
            src="https://www.datocms-assets.com/15671/1611858906-step1-find-code-export-once.mp4"
            type={"video/mp4"}
          />
        </video>
      </Box>

      <Button
        variant="contained"
        color="primary"
        fullWidth
        component={Link}
        to={getActivationPath("/enter-code")}
      >
        {t("activation.step1FindCode.cta")}
      </Button>
    </ActivationLayout>
  );
}
