import React from "react";
import { ProductVariantType } from "../../../../types/airgraft";
import {
  CustomPodKeyInfo,
  CustomPodVideo,
  PodCoverImgix,
  Wrapper
} from "./styles";

type Props = {
  podKey: string;
  productVariant?: ProductVariantType;
};

export default function PodProductCard({ podKey, productVariant }: Props) {
  return (
    <Wrapper>
      <PodCoverImgix
        src={
          productVariant?.featuredImage?.url ||
          "https://www.datocms-assets.com/15671/1632332482-activation-pod-info-fallback.png"
        }
        width={350}
        height={350}
        imgixParams={{
          ar: "1",
          fit: "crop",
          auto: "format,compress"
        }}
        htmlAttributes={{
          draggable: false
        }}
      />

      <CustomPodKeyInfo podKey={podKey} />

      <CustomPodVideo />
    </Wrapper>
  );
}
