import React from "react";
import { SvgIcon, SvgIconProps } from "@material-ui/core";

export default function SmallArrowIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 10 10" {...props}>
      <path
        d="M1.33594 5.99984H0.835938V6.49984H1.33594V5.99984ZM9.24478 6.35339C9.44004 6.15813 9.44004 5.84154 9.24478 5.64628L6.0628 2.4643C5.86754 2.26904 5.55096 2.26904 5.35569 2.4643C5.16043 2.65956 5.16043 2.97615 5.35569 3.17141L8.18412 5.99984L5.35569 8.82826C5.16043 9.02353 5.16043 9.34011 5.35569 9.53537C5.55096 9.73063 5.86754 9.73063 6.0628 9.53537L9.24478 6.35339ZM1.33594 6.49984H8.89123V5.49984H1.33594V6.49984ZM1.83594 5.99984V0.71875H0.835938V5.99984H1.83594Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
