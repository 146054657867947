import React from "react";
import { styled, Typography, TypographyProps } from "@material-ui/core";

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "2.4rem",
  lineHeight: "3rem",
  fontFamily: "Reckless, Georgia",
  fontWeight: 400,
  "@media (max-height: 640px)": {
    fontSize: "2rem",
    lineHeight: "2.4rem"
  }
}));

export default function ActivationHeading(props: TypographyProps) {
  return <CustomTypography {...props} />;
}
