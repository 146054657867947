export function getMultistepNavigationLabel({
  currentStep,
  currentStepLabel,
  totalSteps
}: {
  currentStep: number;
  totalSteps: number;
  currentStepLabel: string;
}) {
  // prettier-ignore
  return `Step ${currentStep} of ${totalSteps}${currentStepLabel ? ` / ${currentStepLabel}` : ""}`;
}
