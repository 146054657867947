import React, { useEffect, useLayoutEffect } from "react";
import { Box, BoxProps, Container, Hidden } from "@material-ui/core";
import { useParams, useLocation } from "@reach/router";
import { usePodDetails } from "../../hooks/usePodDetails";
import Alert from "@material-ui/lab/Alert";
import { getFullWindowMinHeight } from "./utils";
import useXSmallHeightMediaQuery from "../../../../hooks/use-xsmall-height-mq";
import MultistepNavigationLabel from "../../../../components/multistep-navigation/components/NavigationLabel";
import {
  getNavigationIndexFromPathname,
  getNavigationSteps
} from "../Navigation/utils";
import { useNavigationState } from "../../hooks/useNavigationState";
import ActivationPageSkeleton from "../PageSkeleton";
import useTranslate from "../../../../hooks/use-translate";

type Props = {
  children: JSX.Element | JSX.Element[];
  showLoading?: boolean;
  fullHeight?: boolean;
  hideMultistepsNavigation?: boolean;
} & BoxProps;

export default function ActivationLayout({
  children,
  showLoading,
  fullHeight,
  hideMultistepsNavigation,
  ...boxProps
}: Props) {
  const t = useTranslate();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const routeParams = useParams();
  const { pathname } = useLocation();

  // Navigation
  const navigationState = useNavigationState();

  const {
    data: podDetails,
    isLoading: isLoadingPodDetails,
    error: podDetailsError
  } = usePodDetails(routeParams?.podKeyOrSerialNumber);

  // Render variables
  const shouldRenderError =
    routeParams?.podKeyOrSerialNumber &&
    !isLoadingPodDetails &&
    podDetailsError;

  const shouldRenderLoading =
    !shouldRenderError &&
    ((routeParams?.podKeyOrSerialNumber && isLoadingPodDetails) || showLoading);

  const shouldRenderChildren =
    (!routeParams?.podKeyOrSerialNumber ||
      (!isLoadingPodDetails && !podDetailsError && podDetails)) &&
    !showLoading;

  // Auto scroll top after page is loaded and children has been rendered
  useLayoutEffect(() => {
    if (shouldRenderChildren) {
      setTimeout(() => window.scrollTo({ top: 0 }), 0);
    }
  }, [shouldRenderChildren]);

  // On mount: Force scroll top
  useEffect(() => window.scroll({ top: 0 }), []);

  return (
    <Container maxWidth={"sm"}>
      {/* Page content */}
      <Box
        paddingTop={{ xs: isXSmallHeight ? 1 : 3, sm: 6, md: 8 }}
        paddingBottom={{ xs: isXSmallHeight ? 1 : 3, sm: 6 }}
        // prettier-ignore
        minHeight={ fullHeight ? { xs: getFullWindowMinHeight(), sm: "0px" } : 0 }
        display="flex"
        flexDirection="column"
        justifyContent={fullHeight ? "space-between" : "flex-start"}
        {...boxProps}
      >
        {shouldRenderChildren && (
          <>
            {!hideMultistepsNavigation && (
              <Hidden xsDown implementation="css">
                <Box mb={3}>
                  <MultistepNavigationLabel
                    steps={getNavigationSteps(navigationState).map(key =>
                      t(key)
                    )}
                    activeStepIndex={getNavigationIndexFromPathname(
                      pathname,
                      navigationState
                    )}
                  />
                </Box>
              </Hidden>
            )}

            {children}
          </>
        )}

        {/* Loading: Fetching pod details */}
        {shouldRenderLoading && <ActivationPageSkeleton />}
      </Box>

      {/* Error: Invalid pod id */}
      {shouldRenderError && (
        <Alert severity="error">{t("activation.invalidPodKey")}</Alert>
      )}
    </Container>
  );
}
