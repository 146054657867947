import {
  Modal,
  styled,
  ModalProps,
  Box,
  IconButton,
  useTheme,
  alpha
} from "@material-ui/core";
import React from "react";
import CloseIcon from "../../../../../components/icons/close";

// @ts-ignore
const CustomModal = styled(Modal)(({ theme }) => ({
  zIndex: "9000 !important",
  "& > div:first-child": {
    backgroundColor: `${alpha(theme.palette.grey[200], 0.5)} !important`,
    backdropFilter: "blur(4px)"
  }
}));

export default function FindPodKeyModal(props: Omit<ModalProps, "children">) {
  const theme = useTheme();
  return (
    <CustomModal {...props}>
      <Box
        display="flex"
        justifyContent="center"
        p={2}
        pt={8}
        position="absolute"
        bottom={0}
        left={0}
        style={{ outline: 0 }}
        width="100%"
      >
        <video
          controls={false}
          preload="auto"
          muted
          playsInline
          autoPlay
          style={{ width: `${300 * 0.4}px`, height: `${500 * 0.4}px` }}
          disableRemotePlayback={true}
        >
          <source
            src="https://www.datocms-assets.com/15671/1611858906-step1-find-code-export-once.mp4"
            type={"video/mp4"}
          />
        </video>
        {props.onClose && (
          <IconButton
            color="secondary"
            onClick={e => props.onClose(e, "backdropClick")}
            aria-label="close"
            style={{
              position: "absolute",
              top: theme.spacing(1),
              right: theme.spacing(1)
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
      </Box>
    </CustomModal>
  );
}
