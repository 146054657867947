import React, { useState } from "react";
import {
  Box,
  Button,
  Hidden,
  IconButton,
  useTheme,
  Container,
  Typography
} from "@material-ui/core";
import Progressbar from "./components/Progessbar";
import ChevronLeft from "../icons/chevron-left";
import MultistepNavigationLabel from "./components/NavigationLabel";
import { Link, navigate } from "gatsby";
import LocalizedLink from "../localized-link";
import useLocalizedLocationPath from "../../hooks/use-localized-location-path";
import BasicModal from "../basic-modal";
import ExpressDeliveryLogo from "../../containers/express-delivery/ExpressDeliveryLogo";
import useTranslate from "../../hooks/use-translate";
import HeaderLogo from "../../containers/header/BaseHeader/HeaderLogo";

type Props = {
  steps: string[];
  activeStepIndex: number;
  previousStepPath?: string;
  confirmBeforeLeaving?: boolean;
  variant?: "default" | "express-delivery";
};

const MultiStepNavigation = React.memo(
  ({
    activeStepIndex,
    steps,
    previousStepPath,
    confirmBeforeLeaving,
    variant
  }: Props) => {
    const theme = useTheme();
    const t = useTranslate();
    const getLocalizedPath = useLocalizedLocationPath();

    const [isConfirmationOpen, setConfirmationOpen] = useState(false);
    const handleDesktopLogoClick = (
      e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      if (confirmBeforeLeaving) {
        e.stopPropagation();
        e.preventDefault();
        setConfirmationOpen(true);
      }
    };

    const backButtonProps = previousStepPath
      ? { component: Link, to: previousStepPath }
      : { onClick: () => navigate(-1) };

    return (
      <>
        {/* Mobile */}
        <Hidden smUp implementation="css">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="var(--header-height)"
            bgcolor="background.default"
            zIndex={1200}
          >
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              paddingX={1}
              height="calc(100% - 3px)"
            >
              <Box width={theme.spacing(6)}>
                <IconButton
                  color="secondary"
                  aria-label="back"
                  size="small"
                  {...backButtonProps}
                >
                  <ChevronLeft />
                </IconButton>
              </Box>

              <MultistepNavigationLabel
                steps={steps}
                activeStepIndex={activeStepIndex}
              />

              <Box width={theme.spacing(6)} />
            </Box>

            <Progressbar
              activeStepIndex={activeStepIndex}
              totalStepsCount={steps.length}
              variant={variant}
            />
          </Box>
        </Hidden>

        {/* Desktop */}
        <Hidden xsDown implementation="css">
          <Box
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height={{
              xs: "calc(var(--header-height) + 3px)",
              sm: "calc(var(--header-height) + 6px)"
            }}
            bgcolor="background.default"
            zIndex={1200}
          >
            <Container
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingLeft: theme.spacing(2),
                paddingRight: theme.spacing(2)
              }}
              disableGutters
            >
              {/* Back button */}
              <Box width={theme.spacing(13)}>
                <Button
                  startIcon={<ChevronLeft />}
                  style={{ width: "100px" }}
                  className="compact"
                  {...backButtonProps}
                >
                  {t("back")}
                </Button>
              </Box>

              {/* Logo */}
              <LocalizedLink
                to="/"
                style={{
                  width: "156px",
                  height: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
                onClick={handleDesktopLogoClick}
              >
                {variant !== "express-delivery" && <HeaderLogo />}
                {variant === "express-delivery" && (
                  <ExpressDeliveryLogo height="14px" />
                )}
              </LocalizedLink>

              {/* Empty right space */}
              <Box width={theme.spacing(13)} />
            </Container>
            <Progressbar
              activeStepIndex={activeStepIndex}
              totalStepsCount={steps.length}
            />
          </Box>
        </Hidden>

        <BasicModal
          open={isConfirmationOpen}
          onClose={() => setConfirmationOpen(false)}
        >
          <>
            <Box>
              <Typography variant="h5">
                {t("multistepNavigation.areyouSure")}
              </Typography>
            </Box>
            <Box marginY={2}>
              <Typography>
                {t("multistepNavigation.progressWillBeLost")}
              </Typography>
            </Box>
            <Box mt={2}>
              <Box mb={2}>
                <Button
                  onClick={() => navigate(getLocalizedPath("/"))}
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  {t("multistepNavigation.leave")}
                </Button>
              </Box>

              <Button
                autoFocus
                onClick={() => setConfirmationOpen(false)}
                variant="contained"
                fullWidth
              >
                {t("multistepNavigation.stay")}
              </Button>
            </Box>
          </>
        </BasicModal>
      </>
    );
  }
);

export default MultiStepNavigation;
