type FullWindowMinHeightOptionsType = {
  offset?: string | number;
  initial100vh?: boolean;
};

/**
 * Helper to get a dynamic min height to fill full mobile window height.
 */
export function getFullWindowMinHeight(
  options?: FullWindowMinHeightOptionsType
) {
  // prettier-ignore
  return `calc(var(${options?.initial100vh ? '--initial-100vh' : '--100vh'}) - var(--header-height) - ${options?.offset || 0}px)`;
}
