import { RouteComponentProps, useLocation } from "@reach/router";
import { navigate } from "gatsby";
import React from "react";
import { useQueryClient } from "react-query";

import useCustomSnackbar from "../../../../hooks/use-custom-snackbar";
import useLocalizedLocationPath from "../../../../hooks/use-localized-location-path";
import useTranslate from "../../../../hooks/use-translate";
import Authenticate from "../../../authentication/containers/Authenticate";
import ActivationLayout from "../../components/Layout";
import { getFullWindowMinHeight } from "../../components/Layout/utils";
import useActivationPath from "../../hooks/useActivationPath";
import { usePodDetails } from "../../hooks/usePodDetails";
import { saveToMyPods } from "../../utils/pod-activation";
import { isPodActivationRequired } from "../../utils/pod-flags";

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

export default function ActivationUserRegister({
  podKeyOrSerialNumber
}: Props) {
  const t = useTranslate();
  const getLocalizedPath = useLocalizedLocationPath();
  const getActivationPath = useActivationPath();
  const queryClient = useQueryClient();
  const snackbar = useCustomSnackbar();

  const { pathname } = useLocation();
  const isSignIn = pathname.includes("/sign-in");

  const { data: podDetails } = usePodDetails(podKeyOrSerialNumber);
  const isActivationRequired = isPodActivationRequired(podDetails);

  const handleAuthentication = async () => {
    // No activation required: Save pod and redirect to My Pods
    if (!isActivationRequired) {
      await saveToMyPods(podDetails.podSerialNumber);
      queryClient.invalidateQueries("user-activated-pod-history");
      navigate(getLocalizedPath("/user/pods"));
      snackbar.showSuccess(t("activation.step1PodInfo.saveToMyPods.success"));
    } else {
      navigate(getActivationPath(`${podKeyOrSerialNumber}/activation-light`));
    }
  };

  return (
    <ActivationLayout fullHeight>
      <Authenticate
        type={isSignIn ? "sign-in" : "register"}
        labels={{
          signIn: {
            title: isActivationRequired
              ? t("activation.step2Auth.signIn.title")
              : t("activation.step2Auth.signIn.title.saveToMyPods")
          }
        }}
        onAuthentication={handleAuthentication}
        fullHeight
        minHeight={{
          xs: getFullWindowMinHeight({ offset: 48 }),
          sm: "0px"
        }}
      />
    </ActivationLayout>
  );
}
