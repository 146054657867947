import React, { useEffect } from "react";
import { useLocation } from "@reach/router";
import { getParamsFromPathname } from "../components/Navigation/utils";
import useAuth from "../../../hooks/use-auth";
import { useSetState } from "react-use";
import { usePodDetails } from "../hooks/usePodDetails";
import {
  isPodActivationRequired,
  isUserAccountRequiredForPodActivation
} from "../utils/pod-flags";

export type NavigationStateType = {
  enteredPodKeyManually: boolean; // Did the user entered the pod key manually?
  authenticationTypeUsed: "sign-in" | "register"; // Did the user used sign-in or register?
  wasAuthenticated: boolean; // Was the user authenticated initially?
  isActivationRequired: boolean; // Is pod unlockable?
  isAccountRequired: boolean; // Is account required to unlock pod?
};

interface NavigationStateContextType {
  state: NavigationStateType;
}

export const NavigationStateContext =
  React.createContext<NavigationStateContextType>({
    state: null
  });

/**
 * Provide Navigation state to the childrens. This is used to share the dynamic navigation logic and edge cases to various components.
 */
export default function NavigationStateProvider({
  children
}: {
  children: JSX.Element | JSX.Element[];
}) {
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth();
  const params = getParamsFromPathname(pathname);

  // Navigation state
  const [state, setState] = useSetState<NavigationStateType>(null);

  // Pod details
  const { data: podDetails } = usePodDetails(
    params?.podKeyOrSerialNumber !== "enter-code"
      ? params?.podKeyOrSerialNumber
      : null
  );
  useEffect(() => {
    if (podDetails) {
      setState({
        isAccountRequired: isUserAccountRequiredForPodActivation(podDetails),
        isActivationRequired: isPodActivationRequired(podDetails)
      });
    }
  }, [podDetails]);

  // On page load: Initialize navigation state
  useEffect(() => {
    setState({
      wasAuthenticated: isAuthenticated,
      enteredPodKeyManually: !params.podKeyOrSerialNumber
    });
  }, [isAuthenticated]);

  // When location changes:  Detect if user is using sign-in or register.
  // This will determine where to redirect when clicking prev button
  useEffect(() => {
    if (pathname.includes("/sign-in")) {
      setState({ authenticationTypeUsed: "sign-in" });
    } else if (pathname.includes("/register")) {
      setState({ authenticationTypeUsed: "register" });
    }
  }, [pathname]);

  return (
    <NavigationStateContext.Provider
      value={{
        state
      }}
    >
      {children}
    </NavigationStateContext.Provider>
  );
}
