import React from "react";
import { styled, Typography } from "@material-ui/core";
import { getMultistepNavigationLabel } from "../../utils";

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.4rem",
  lineHeight: 1,
  textTransform: "uppercase",
  letterSpacing: "1px",
  fontFamily: "Roobert, Arial",
  [theme.breakpoints.down("xs")]: {
    fontSize: "1.2rem"
  }
}));

export default function MultistepNavigationLabel({
  steps,
  activeStepIndex
}: {
  steps: string[];
  activeStepIndex: number;
}) {
  return (
    <CustomTypography>
      {getMultistepNavigationLabel({
        currentStep: activeStepIndex + 1,
        totalSteps: steps.length,
        currentStepLabel: steps[activeStepIndex]
      })}
    </CustomTypography>
  );
}
