import { styled, Typography } from "@material-ui/core";
import Imgix from "react-imgix";
import PodVideo from "../../../../components/pod-video";
import PodKeyInfo from "../PodKeyInfo";

export const Wrapper = styled("div")(({ theme }) => ({
  position: "relative",
  alignSelf: "center"
}));

export const PodCoverImgix = styled(Imgix)(({ theme }) => ({
  width: "min(30vh, 350px)",
  height: "auto",
  backgroundColor: theme.palette.grey[300],
  userSelect: "none",
  borderRadius: "3px",
  overflow: "hidden",
  "&::before": {
    // Force 1:1 apect ratio when image is loading
    content: "",
    display: "block",
    paddingTop: "100%"
  },
  // Desktop
  [theme.breakpoints.up("md")]: {
    maxWidth: "100%"
  },
  "@media (max-height: 640px)": {
    maxWidth: "min(28vh, 350px)"
  }
}));

export const CustomPodKeyInfo = styled(PodKeyInfo)(({ theme }) => ({
  marginTop: "33px",
  "@media (max-height: 640px)": {
    transform: "scale(0.8)",
    marginTop: "24px"
  }
}));

export const CustomPodVideo = styled(PodVideo)(({ theme }) => ({
  bottom: 0,
  right: "-60px",
  "@media (max-height: 640px)": {
    transform: "scale(0.65)",
    bottom: "-16px",
    right: "-64px"
  }
}));
