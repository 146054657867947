import { Box, Button } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import useProductQuery from "../../../../../hooks/use-product-query";
import useXSmallHeightMediaQuery from "../../../../../hooks/use-xsmall-height-mq";
import ActivationHeading from "../../../components/ActivationHeading";
import ActivationLayout from "../../../components/Layout";
import PodKeyInfo from "../../../components/PodKeyInfo";
import useActivationPath from "../../../hooks/useActivationPath";
import { usePodDetails } from "../../../hooks/usePodDetails";

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

export default function ActivationStep5TroubleShootPodKey({
  podKeyOrSerialNumber
}: Props) {
  const getActivationPath = useActivationPath();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const { data: podDetails } = usePodDetails(podKeyOrSerialNumber);
  const { data: podProduct } = useProductQuery({
    batchId: podDetails?.batchId
  });
  const podProductVariant =
    podProduct &&
    podProduct.variants.find(v => v.batchIds.includes(podDetails?.batchId));

  return (
    <ActivationLayout fullHeight>
      <Box mb={{ xs: 0, sm: 4 }}>
        <ActivationHeading>
          Pop your pod out and confirm that it matches this code:
        </ActivationHeading>
      </Box>

      <PodKeyInfo
        podKey={podDetails?.podKey}
        brandName={podProductVariant?.brand?.name}
        withPodImage
      />

      <Box mt={{ xs: 0, sm: 4 }}>
        <Box mb={isXSmallHeight ? 1 : 2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to={getActivationPath(`${podKeyOrSerialNumber}/activation`)}
          >
            {"It’s a match"}
          </Button>
        </Box>

        <Button
          variant="contained"
          fullWidth
          component={Link}
          to={getActivationPath(
            `${podKeyOrSerialNumber}/troubleshoot-reenter-pod`
          )}
        >
          {"the code doesn’t match"}
        </Button>
      </Box>
    </ActivationLayout>
  );
}
