import { Box, Button, useTheme } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";
import React, { useRef, useState } from "react";
import SmallArrowIcon from "../../../../../components/icons/small-arrow";
import useCustomSnackbar from "../../../../../hooks/use-custom-snackbar";
import useTranslate from "../../../../../hooks/use-translate";
import useXSmallHeightMediaQuery from "../../../../../hooks/use-xsmall-height-mq";
import { clearReactCodeInput } from "../../../../authentication/containers/Authenticate/Form/components/CustomReactCodeInput";
import VerifyingCode from "../../../../authentication/containers/Authenticate/Form/components/VerifyingCode";
import ActivationHeading from "../../../components/ActivationHeading";
import ActivationLayout from "../../../components/Layout";
import { POD_KEYS_COUNT, POD_KEYS_PATTERN } from "../../../constants";
import useActivationPath from "../../../hooks/useActivationPath";
import { PodDetailsType } from "../../../types";
import {
  getPodDetails,
  INVALID_POD_KEY_ERROR
} from "../../../utils/pod-details";
import CustomReactCodeInput from "./CustomReactCodeInput";
import FindPodKeyModal from "./FindPodKeyModal";
import KeyIcon from "./KeyIcon";

type Props = RouteComponentProps<Record<string, unknown>>;

export default function ActivationStep1PodEnterCode({}: Props) {
  const t = useTranslate();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const getActivationPath = useActivationPath();
  const theme = useTheme();
  const snackbar = useCustomSnackbar();
  const codeInputRef = useRef<any>(null);

  // Code
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isValidPodKey, setValidPodKey] = useState(false);

  const handleCodeChange = (value: string) => {
    if (error) {
      setError(false);
    }
    if (value.length >= POD_KEYS_COUNT) {
      submitCode(value);
    }
  };

  const handleClearCode = () => {
    setError(false);
    clearReactCodeInput(codeInputRef.current);
  };

  // When user enters all keys
  const submitCode = async (code: string) => {
    let podInfo: PodDetailsType = null;
    try {
      setLoading(true);
      podInfo = await getPodDetails(code);
      setLoading(false);
      setValidPodKey(true);
    } catch (e) {
      setLoading(false);
      if (e?.message === INVALID_POD_KEY_ERROR) {
        setError(true);
      } else {
        snackbar.showError(t("error.cantConnectToServer"));
      }
      return;
    }
    // Move to next step
    navigate(getActivationPath(`/${podInfo.podKey}`));
  };

  let keyIconColor = error ? theme.palette.error.main : null;
  if (isValidPodKey) {
    keyIconColor = theme.palette.info.main;
  }

  // UI
  const [isHelpModalOpen, setHelpModalOpen] = useState(false);

  return (
    <ActivationLayout>
      <Box mb={isXSmallHeight ? 2 : 4}>
        <ActivationHeading variant="h5">
          {t("activation.enterPodCodeBelow")}
        </ActivationHeading>
      </Box>

      <Box display="flex" justifyContent="center">
        <KeyIcon fill={keyIconColor} />
      </Box>

      <Box display="flex" justifyContent="center" mt={2}>
        <CustomReactCodeInput
          fields={POD_KEYS_COUNT}
          type="text"
          name="code"
          inputMode="latin"
          forceUppercase
          onChange={handleCodeChange}
          pattern={POD_KEYS_PATTERN}
          inputStyle={{}}
          inputStyleInvalid={{}}
          isValid={!error}
          isValidPodKey={isValidPodKey}
          disabled={loading}
          // @ts-ignore
          ref={codeInputRef}
        />
      </Box>

      {/* Error */}
      {error && (
        <Box marginTop={2}>
          <Alert severity="error">
            {t("activation.invalidCodeEnterAgain")}
          </Alert>

          <Box mt={2}>
            <Button variant="contained" fullWidth onClick={handleClearCode}>
              {t("activation.clearCode")}
            </Button>
          </Box>
        </Box>
      )}

      {/* Where is the code again? */}
      <Box marginTop={2} display="flex" justifyContent="center">
        {!loading && !isValidPodKey && !error && (
          <Button
            variant="text"
            size="small"
            startIcon={<SmallArrowIcon fontSize="small" />}
            onClick={() => setHelpModalOpen(true)}
          >
            {t("activation.remindMeAgain")}
          </Button>
        )}

        {loading && <VerifyingCode />}
      </Box>

      {/* Where is code bottom modal */}
      <FindPodKeyModal
        open={isHelpModalOpen}
        onClose={() => setHelpModalOpen(false)}
      />
    </ActivationLayout>
  );
}
