import {
  Box,
  Button,
  CircularProgress,
  makeStyles,
  styled,
  Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { getCssVh } from "../../../../../helpers/vh-css-variables";
import { useAuthContextSelector } from "../../../../../hooks/use-auth";
import useIsAuthenticated from "../../../../../hooks/use-is-authenticated";
import useIsMobile from "../../../../../hooks/use-is-mobile";
import useTranslate from "../../../../../hooks/use-translate";
import useXSmallHeightMediaQuery from "../../../../../hooks/use-xsmall-height-mq";
import ActivationHeading from "../../../components/ActivationHeading";
import ActivationSequence from "../../../components/ActivationSequence";
import ActivationLayout from "../../../components/Layout";
import useActivationPath from "../../../hooks/useActivationPath";
import usePodActivationSequence from "../../../hooks/usePodActivationSequence";
import { usePodDetails } from "../../../hooks/usePodDetails";
import useRequireUnlockAllowed from "../../../hooks/useRequireUnlockAllowed";
import { logActivationAttempt } from "../../../utils/pod-activation";

const useStyles = makeStyles({
  "@global": {
    "@keyframes rainbowGradient": {
      "0%": {
        backgroundPosition: "0% 0%"
      },
      "50%": {
        backgroundPosition: "50% 0%"
      },
      "100%": {
        backgroundPosition: "100% 0%"
      }
    }
  },
  rainbow: {
    fontWeight: 600,
    background:
      "linear-gradient(90deg, #FF0000, #FFEA29, #55FF5C, #00C2FF, #FA00FF)",
    backgroundSize: "500%",
    animation: "rainbowGradient 2s linear infinite"
  }
});

const CustomVideo = styled("video")(() => ({
  width: "100%",
  height: "auto !important",
  maxHeight: getCssVh(40),
  "@media (max-height: 500px)": {
    maxHeight: getCssVh(30)
  }
}));

const DesktopContainer = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("sm")]: {
    borderRadius: "8px",
    background: theme.palette.grey[100],
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
    position: "relative",
    margin: "0 auto",
    maxWidth: "480px",
    width: "100%",
    height: "min(675px, calc(100vh - var(--header-height) - 48px))",
    marginTop: theme.spacing(4),
    overflow: "hidden"
  }
}));

const BackgroundCountdownTypography = styled(Typography)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  fontFamily: "'Roboto Mono', monospace",
  fontSize: "min(100vh, 700px)",
  lineHeight: 0.85,
  textAlign: "center",
  color: theme.palette.grey[50],
  zIndex: -1,
  userSelect: "none",
  [theme.breakpoints.up("sm")]: {
    fontSize: "min(85vh, 700px)"
  }
}));

const CountdownTypography = styled(Typography)(({ theme }) => ({
  fontFamily: "'Roboto Mono', monospace",
  fontSize: "105px",
  lineHeight: "105px",
  textAlign: "center",
  color: theme.palette.primary.main,
  userSelect: "none"
}));

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

type ActivationUnlockState = "INITIAL" | "COUNTDOWN" | "SEQUENCE";

const COUNTDOWN_TOTAL_TIME = 3;

export default function ActivationStep4Activation({
  podKeyOrSerialNumber
}: Props) {
  const t = useTranslate();
  const getActivationPath = useActivationPath();
  const classNames = useStyles();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const isAuthenticated = useIsAuthenticated();

  /**
   * Change video based on breakpoint
   */
  const isMobile = useIsMobile();

  // Dont show video until we are sure about which breakpoints. Both are false by default.
  const videoUrl = isMobile
    ? "https://www.datocms-assets.com/15671/1611859419-step3-screen-placement-export-loop.mp4"
    : "https://www.datocms-assets.com/15671/1617127023-step3-screen-placement-desk-loop.mp4";

  // Require authentication
  const isUnlockAllowed = useRequireUnlockAllowed(podKeyOrSerialNumber);

  // Pod details & sequence
  const { data: podDetails } = usePodDetails(podKeyOrSerialNumber);
  const {
    data: podActivation,
    isLoading: loadingActivation,
    error: errorActivation
  } = usePodActivationSequence(podDetails?.podSerialNumber);

  // UI Step & states
  const [step, setStep] = useState<ActivationUnlockState>("INITIAL");
  const [countdownTime, setCountdownTime] =
    useState<number>(COUNTDOWN_TOTAL_TIME);

  const handleCountdownOver = () => {
    setCountdownTime(COUNTDOWN_TOTAL_TIME);
    setStep("SEQUENCE");
  };

  // Decreate countdown after 1 second. If countdown is over, invoke callback
  const decreaseCountdownTime = (newCountdownTime: number) => {
    if (newCountdownTime >= 0) {
      setTimeout(() => {
        setCountdownTime(newCountdownTime);
        decreaseCountdownTime(newCountdownTime - 1);
      }, 1000);
    } else {
      handleCountdownOver();
    }
  };

  // Start activation: Show countdown
  const startActivation = e => {
    setStep("COUNTDOWN");
    decreaseCountdownTime(COUNTDOWN_TOTAL_TIME - 1);
  };

  // After activation sequence is done
  const handleActivationSequenceComplete = () => {
    if (isAuthenticated) {
      try {
        // Log activation
        logActivationAttempt(podDetails.podSerialNumber);
      } catch (e) {}
    }
    navigate(getActivationPath(`${podKeyOrSerialNumber}/confirmation`));
  };

  // Invalid activation
  if (!podActivation && errorActivation) {
    return (
      <ActivationLayout>
        <Box marginY={2}>
          <Alert severity="error">
            {t("error.cantLoadActivationSequence")}
          </Alert>
        </Box>

        <Button
          variant="contained"
          onClick={() => window.location.reload()}
          fullWidth
        >
          {t("reload")}
        </Button>
      </ActivationLayout>
    );
  }

  // Initial screen
  if (step === "INITIAL") {
    return (
      <ActivationLayout
        showLoading={!isUnlockAllowed || loadingActivation}
        fullHeight
      >
        <Box mb={{ xs: 2, sm: 4 }}>
          <ActivationHeading>
            {t("activation.step4Activation.title1")}
          </ActivationHeading>

          <Box mt={1}>
            <ActivationHeading>
              {t("activation.step4Activation.title2")}
            </ActivationHeading>
          </Box>
        </Box>

        <Box marginX="auto">
          {videoUrl && (
            <CustomVideo
              key={isMobile ? "mobile" : "desktop"}
              controls={false}
              preload="auto"
              muted
              playsInline
              autoPlay
              loop={isMobile}
              disableRemotePlayback={true}
            >
              <source src={videoUrl} type={"video/mp4"} />
            </CustomVideo>
          )}
        </Box>

        <Box marginTop={{ xs: 2, sm: 4 }}>
          <Box mb={isXSmallHeight ? 1 : 2}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={!podActivation}
              onClick={startActivation}
              onTouchStart={startActivation}
              className={classNames.rainbow}
            >
              {!podActivation ? (
                <CircularProgress size={16} color="secondary" />
              ) : (
                t("activation.step4Activation.cta")
              )}
            </Button>
          </Box>

          <Button
            variant="contained"
            fullWidth
            component={Link}
            to={getActivationPath(`${podKeyOrSerialNumber}/activation-why`)}
          >
            {t("activation.step4Activation.secondaryCta")}
          </Button>
        </Box>
      </ActivationLayout>
    );
  }

  // Countdown screen
  if (step === "COUNTDOWN") {
    return (
      <DesktopContainer>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          padding={3}
          paddingBottom={5}
          position={{ xs: "fixed", sm: "absolute" }}
          top={0}
          left={0}
          width="100%"
          height="100%"
          zIndex={9000}
          bgcolor="grey.200"
        >
          <Box mb={2}>
            <CountdownTypography>{countdownTime}</CountdownTypography>
            <BackgroundCountdownTypography>
              {countdownTime}
            </BackgroundCountdownTypography>
          </Box>
          <ActivationHeading
            style={{ textAlign: "center", userSelect: "none" }}
          >
            {t("activation.step4Activation.countdown")}
          </ActivationHeading>
        </Box>
      </DesktopContainer>
    );
  }

  // Sequence
  if (step === "SEQUENCE") {
    return (
      <DesktopContainer>
        <ActivationSequence
          podActivation={podActivation}
          onComplete={handleActivationSequenceComplete}
        />
      </DesktopContainer>
    );
  }
}
