import { Box, Button, Hidden, Typography } from "@material-ui/core";
import { Link } from "gatsby";
import React from "react";
import { useContextSelector } from "use-context-selector";
import useLocalizedLocationPath from "../../../../hooks/use-localized-location-path";
import useTranslate from "../../../../hooks/use-translate";
import { LayoutContext } from "../../../../providers/layout";
import ActivationLayout from "../Layout";

export default function UnavailableInYourRegion() {
  const t = useTranslate();
  const getLocalizedLink = useLocalizedLocationPath();
  const toggleMobileCountryMenu = useContextSelector(
    LayoutContext,
    c => c.toggleMobileCountryMenu
  );
  const toggleDesktopCountryLanguageMenu = useContextSelector(
    LayoutContext,
    c => c.toggleDesktopCountryLanguageMenu
  );
  return (
    <ActivationLayout fullHeight hideMultistepsNavigation>
      <Box>
        <Box
          marginTop={{ xs: -3 }}
          marginX={{ xs: -2, md: -4 }}
          style={{
            backgroundImage: `url(https://www.datocms-assets.com/15671/1666193325-unlock-unavailable-in-your-region.png)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center bottom",
            backgroundSize: "cover",
            aspectRatio: "1.5"
          }}
          display="flex"
          alignItems="flex-start"
          justifyContent="flex-start"
          position="relative"
          minHeight={{ xs: "200px", md: "350px" }}
          flex={1}
        />

        <Box marginY={{ xs: 2 }}>
          <Hidden smUp implementation="css">
            <Typography variant="h4">
              {t("activation.unavailableInYourRegion.title")}
            </Typography>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Typography variant="h5">
              {t("activation.unavailableInYourRegion.title")}
            </Typography>
          </Hidden>
        </Box>
      </Box>

      <Box mt={{ xs: 0, sm: 4 }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          component={Link}
          to={getLocalizedLink("/")}
        >
          {t("activation.unavailableInYourRegion.cta")}
        </Button>

        <Box marginTop={{ xs: 2 }}>
          <Hidden mdUp implementation="css">
            <Button
              fullWidth
              variant="text"
              onClick={() => toggleMobileCountryMenu(true)}
            >
              {t("activation.unavailableInYourRegion.changeRegion")}
            </Button>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Button
              fullWidth
              variant="text"
              onClick={() => toggleDesktopCountryLanguageMenu(true)}
            >
              {t("activation.unavailableInYourRegion.changeRegion")}
            </Button>
          </Hidden>
        </Box>
      </Box>
    </ActivationLayout>
  );
}
