import { NavigationStateType } from "../../providers/navigation-state-provider";

/**
 * The <ActivationNavigation> is outside the router to prevent fading-in and out when changing page.
 * But this means we dont have have access to the route parameters using useParams.
 * The solution is to extract the pod id from the location pathname manually.
 */
export function getParamsFromPathname(pathname: string): {
  podKeyOrSerialNumber: string | null;
  step: string | null;
} {
  // /us/en/unlock/xxx/register => ['xxx', 'register']
  const paramsList = pathname
    .split("/")
    .filter(p => p?.length > 0)
    .slice(3);

  // First param is pod id
  const podKeyOrSerialNumber = paramsList[0];

  // Second param
  const step = paramsList.length === 2 ? paramsList[1].toLowerCase() : null;
  return {
    podKeyOrSerialNumber,
    step
  };
}

export function getNavigationSteps(state: NavigationStateType) {
  let steps = [
    "activation.steps.enterPodCode",
    "activation.steps.account",
    "activation.steps.unlockPod",
    "activation.steps.confirm"
  ];
  if (state?.wasAuthenticated) {
    steps = [
      "activation.steps.enterPodCode",
      "activation.steps.account",
      "activation.steps.unlockPod",
      "activation.steps.confirm"
    ];
  }

  // Remove navigate steps if pod doesnt require unlock,account
  if (state?.isActivationRequired && state?.isAccountRequired === false) {
    steps = steps.filter(s => s !== "activation.steps.account");
  }
  if (state?.isActivationRequired === false) {
    steps = steps.filter(s => s !== "activation.steps.unlockPod");
    steps = steps.filter(s => s !== "activation.steps.confirm");
  }

  return steps;
}

/**
 * Calculate what is the curent steps index based on current path and state.
 */
export function getNavigationIndexFromPathname(
  pathname,
  state: NavigationStateType
) {
  const { step, podKeyOrSerialNumber } = getParamsFromPathname(pathname);
  const steps = getNavigationSteps(state);

  if (!podKeyOrSerialNumber && !step) {
    // First step /unlock
    return null;
  }
  if (podKeyOrSerialNumber && !step) {
    return steps.indexOf("activation.steps.enterPodCode");
  }
  if (podKeyOrSerialNumber === "enter-code") {
    return steps.indexOf("activation.steps.enterPodCode");
  }
  if (step === "sign-in" || step === "register") {
    return steps.indexOf("activation.steps.account");
  }
  if (step?.startsWith("activation")) {
    return steps.indexOf("activation.steps.unlockPod");
  }
  if (step?.startsWith("troubleshoot")) {
    return steps.indexOf("activation.steps.unlockPod");
  }
  if (step === "confirmation") {
    return steps.indexOf("activation.steps.unlockPod");
  }
  if (step === "complete") {
    return steps.indexOf("activation.steps.confirm");
  }
  return null;
}
