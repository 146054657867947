import { useContext } from "react";
import {
  NavigationStateType,
  NavigationStateContext
} from "../providers/navigation-state-provider";

export function useNavigationState(): NavigationStateType {
  const values = useContext(NavigationStateContext);
  return values.state;
}
