import {
  getGuestPodActivationSequence,
  getPodActivationSequence
} from "../utils/pod-activation";
import { useQuery } from "react-query";
import useIsAuthenticated from "../../../hooks/use-is-authenticated";

export default function usePodActivationSequence(podSerialNumber: string) {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
    return useQuery(
      ["guest-activation-pod-sequence", podSerialNumber],
      () => getGuestPodActivationSequence(podSerialNumber),
      {
        enabled: !!podSerialNumber,
        cacheTime: 0,
        staleTime: 0,
        retry: 3
      }
    );
  }

  return useQuery(
    ["activation-pod-sequence", podSerialNumber],
    () => getPodActivationSequence(podSerialNumber),
    {
      enabled: !!podSerialNumber,
      cacheTime: 0,
      staleTime: 0,
      retry: 3
    }
  );
}
