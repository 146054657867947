import { Box, Button, styled } from "@material-ui/core";
import React from "react";
import ActivationLayout from "../../../components/Layout";
import { RouteComponentProps } from "@reach/router";
import ActivationHeading from "../../../components/ActivationHeading";
import { Link } from "gatsby";
import useRequireAuthenticated from "../../../hooks/useRequireAuthenticated";
import useRequireUnlockAllowed from "../../../hooks/useRequireUnlockAllowed";
import { showIntercom } from "../../../../../services/intercom";
import useXSmallHeightMediaQuery from "../../../../../hooks/use-xsmall-height-mq";
import { getCssVh } from "../../../../../helpers/vh-css-variables";
import useAuth from "../../../../../hooks/use-auth";
import useTranslate from "../../../../../hooks/use-translate";
import useActivationPath from "../../../hooks/useActivationPath";

const CustomVideo = styled("video")(() => ({
  width: `${240 * 0.4}px`,
  height: `${1070 * 0.4}px`,
  "@media (max-height: 600px)": {
    maxHeight: getCssVh(45)
  },
  "@media (max-height: 500px)": {
    maxHeight: getCssVh(40)
  }
}));

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

export default function ActivationStep4ActivationHelpBattery({
  podKeyOrSerialNumber
}: Props) {
  const t = useTranslate();
  const getActivationPath = useActivationPath();
  const { user } = useAuth();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const isUnlockAllowed = useRequireUnlockAllowed(podKeyOrSerialNumber);

  const handleSupportClick = () => {
    showIntercom(user);
  };

  return (
    <ActivationLayout showLoading={!isUnlockAllowed} fullHeight>
      <ActivationHeading>
        {t("activation.step4ActivationHelpBattery.title")}
      </ActivationHeading>

      <Box>
        <Box display="flex" justifyContent="center" mt={2} mb="-30px">
          <CustomVideo
            controls={false}
            preload="auto"
            muted
            playsInline
            autoPlay
            loop
            // @ts-ignore
            disableRemotePlayback={true}
            poster="https://image.mux.com/iVJBw3rDu4f88pTgb3b01rbhBEKTEEXay/thumbnail.jpg?time=0"
          >
            <source
              src="https://www.datocms-assets.com/15671/1611860049-step3-trouble-2-export-loop.mp4"
              type={"video/mp4"}
            />
          </CustomVideo>
        </Box>

        <Box mb={isXSmallHeight ? 1 : 2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to={getActivationPath(`${podKeyOrSerialNumber}/activation`)}
          >
            {t("activation.step4ActivationHelpBattery.cta")}
          </Button>
        </Box>

        <Button variant="contained" fullWidth onClick={handleSupportClick}>
          {t("activation.step4ActivationHelpBattery.secondaryCta")}
        </Button>
      </Box>
    </ActivationLayout>
  );
}
