import React from "react";
import { Router, Location, RouterProps } from "@reach/router";
import { AnimatePresence, motion } from "framer-motion";

export const pageTransitionDuration = 0.15;

const variants = {
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration: pageTransitionDuration,
      delay: pageTransitionDuration,
      when: "beforeChildren"
    }
  },
  exit: {
    opacity: 0,
    transition: { duration: pageTransitionDuration }
  }
};

type Props = {
  children: JSX.Element[];
} & RouterProps;

export default function AnimatedRouter({ children, ...props }: Props) {
  return (
    <Location>
      {({ location }) => (
        <AnimatePresence exitBeforeEnter>
          <motion.main
            key={location.key}
            variants={variants}
            initial="initial"
            animate="enter"
            exit="exit"
            style={{ width: "100%" }}
          >
            <Router location={location} {...props}>
              {children}
            </Router>
          </motion.main>
        </AnimatePresence>
      )}
    </Location>
  );
}
