import { Box, Button, styled, Typography } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import { getCssVh } from "../../../../../helpers/vh-css-variables";
import useTranslate from "../../../../../hooks/use-translate";
import ActivationLayout from "../../../components/Layout";
import useActivationPath from "../../../hooks/useActivationPath";
import useRequireUnlockAllowed from "../../../hooks/useRequireUnlockAllowed";

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

const CustomVideo = styled("video")(({ theme }) => ({
  width: `${270 * 0.4}px`,
  height: `${880 * 0.4}px`,
  "@media (max-height: 600px)": {
    maxHeight: getCssVh(40)
  },
  "@media (max-height: 500px)": {
    maxHeight: getCssVh(35)
  }
}));

export default function ActivationStep4ActivationWhy({
  podKeyOrSerialNumber
}: Props) {
  const t = useTranslate();
  const getActivationPath = useActivationPath();
  const isUnlockAllowed = useRequireUnlockAllowed(podKeyOrSerialNumber);

  return (
    <ActivationLayout fullHeight showLoading={!isUnlockAllowed}>
      <Typography>{t("activation.step4ActivationWhy.title")}</Typography>

      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        marginY={{ xs: 2, sm: 4 }}
      >
        <CustomVideo
          controls={false}
          preload="auto"
          muted
          playsInline
          autoPlay
          // @ts-ignore
          disableRemotePlayback={true}
          poster="https://image.mux.com/kn2xUSev6ijfaSUpKxtiQSH7PA3VvgKv/thumbnail.jpg?time=0"
        >
          <source
            src="https://www.datocms-assets.com/15671/1614003012-step3-why-export-once-fix.mp4"
            type={"video/mp4"}
          />
        </CustomVideo>
      </Box>

      <Box>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          component={Link}
          to={getActivationPath(`${podKeyOrSerialNumber}/activation`)}
        >
          {t("activation.step4ActivationWhy.cta")}
        </Button>
      </Box>
    </ActivationLayout>
  );
}
