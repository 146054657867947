import { Box, Button, styled, useTheme } from "@material-ui/core";
import React, { useEffect } from "react";
import ActivationLayout from "../../../components/Layout";
import { RouteComponentProps } from "@reach/router";
import ActivationHeading from "../../../components/ActivationHeading";
import { Link } from "gatsby";
import useRequireAuthenticated from "../../../hooks/useRequireAuthenticated";
import useRequireUnlockAllowed from "../../../hooks/useRequireUnlockAllowed";
import { logActivationSuccess } from "../../../utils/pod-activation";
import { usePodDetails } from "../../../hooks/usePodDetails";
import useXSmallHeightMediaQuery from "../../../../../hooks/use-xsmall-height-mq";
import { getCssVh } from "../../../../../helpers/vh-css-variables";
import { useQueryClient } from "react-query";
import useTranslate from "../../../../../hooks/use-translate";
import useActivationPath from "../../../hooks/useActivationPath";
import useIsAuthenticated from "../../../../../hooks/use-is-authenticated";

const CustomVideo = styled("video")(({ theme }) => ({
  width: `${240 * 0.4}px`,
  height: `${800 * 0.4}px`,
  "@media (max-height: 600px)": {
    maxHeight: getCssVh(60)
  },
  "@media (max-height: 500px)": {
    maxHeight: getCssVh(50)
  }
}));

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

export default function ActivationStep5ConfirmationQuestion({
  podKeyOrSerialNumber
}: Props) {
  const getActivationPath = useActivationPath();
  const t = useTranslate();
  const theme = useTheme();
  const isAuthenticated = useIsAuthenticated();
  const queryClient = useQueryClient();
  const isXSmallHeight = useXSmallHeightMediaQuery();

  const isUnlockAllowed = useRequireUnlockAllowed(podKeyOrSerialNumber);
  const { data: podDetails } = usePodDetails(podKeyOrSerialNumber);

  const handleSuccessClick = () => {
    try {
      if (isAuthenticated) {
        logActivationSuccess(podDetails.podSerialNumber);
        queryClient.invalidateQueries("user-activated-pod-history");
      }
    } catch (e) {}
  };

  return (
    <ActivationLayout showLoading={!isUnlockAllowed} fullHeight>
      <ActivationHeading>
        {t("activation.step5UnlockConfirmationquestion.title")}
      </ActivationHeading>

      <Box display="flex" justifyContent="center" mt={{ xs: 2, md: 4 }}>
        <CustomVideo
          controls={false}
          preload="auto"
          muted
          playsInline
          autoPlay
          loop
          // @ts-ignore
          disableRemotePlayback={true}
        >
          <source
            src="https://www.datocms-assets.com/15671/1611859734-step4-confirm-export-loop.mp4"
            type={"video/mp4"}
          />
        </CustomVideo>
      </Box>

      <Box marginTop={{ xs: 2, md: 6 }}>
        <Box mb={isXSmallHeight ? 1 : 2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to={getActivationPath(`${podKeyOrSerialNumber}/complete`)}
            onClick={handleSuccessClick}
          >
            {t("activation.step5UnlockConfirmationquestion.cta")}
          </Button>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            fullWidth
            component={Link}
            to={getActivationPath(`${podKeyOrSerialNumber}/troubleshoot`)}
          >
            {t("activation.step5UnlockConfirmationquestion.troubleshoot")}
          </Button>
          <Box width={theme.spacing(4)} />
          <Button
            variant="contained"
            fullWidth
            component={Link}
            to={getActivationPath(`${podKeyOrSerialNumber}/activation`)}
          >
            {t("activation.step5UnlockConfirmationquestion.tryagain")}
          </Button>
        </Box>
      </Box>
    </ActivationLayout>
  );
}
