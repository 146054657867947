import { Box, Button, styled, useTheme } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { Link } from "gatsby";
import React from "react";
import { getCssVh } from "../../../../../helpers/vh-css-variables";
import useXSmallHeightMediaQuery from "../../../../../hooks/use-xsmall-height-mq";
import ActivationHeading from "../../../components/ActivationHeading";
import ActivationLayout from "../../../components/Layout";
import useActivationPath from "../../../hooks/useActivationPath";
import useRequireUnlockAllowed from "../../../hooks/useRequireUnlockAllowed";

const CustomImage = styled("img")(({ theme }) => ({
  width: "auto",
  height: `${526 * 0.5}px`,
  "@media (max-height: 600px)": {
    maxHeight: getCssVh(30)
  },
  "@media (max-height: 500px)": {
    maxHeight: getCssVh(26)
  }
}));

type Props = RouteComponentProps<{ podKeyOrSerialNumber: string }>;

export default function ActivationStep5TroubleShoot({
  podKeyOrSerialNumber
}: Props) {
  const theme = useTheme();
  const getActivationPath = useActivationPath();
  const isXSmallHeight = useXSmallHeightMediaQuery();
  const isUnlockAllowed = useRequireUnlockAllowed(podKeyOrSerialNumber);

  return (
    <ActivationLayout fullHeight showLoading={!isUnlockAllowed}>
      <Box mb={isXSmallHeight ? 1 : 2}>
        <Box mb={isXSmallHeight ? 1 : 2}>
          <ActivationHeading>
            Pop your pod out and back in again. If the light is green or orange
            your pod is unlocked.
          </ActivationHeading>
        </Box>

        <ActivationHeading>
          Otherwise choose the correct option below.
        </ActivationHeading>
      </Box>

      <Box display="flex" justifyContent="center">
        <CustomImage
          src="https://www.datocms-assets.com/15671/1617122719-activation-troubleshoot.png?auto=compress"
          width="79px"
          height="263px"
        />
      </Box>

      {/* CTA */}
      <Box mt={2}>
        <Box mb={isXSmallHeight ? 1 : 2}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            component={Link}
            to={getActivationPath(`${podKeyOrSerialNumber}/complete`)}
          >
            {"Green or orange light"}
          </Button>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Button
            variant="contained"
            className="compact"
            fullWidth
            component={Link}
            to={getActivationPath(
              `${podKeyOrSerialNumber}/troubleshoot-screen`
            )}
          >
            {"Rainbow light 🌈"}
          </Button>
          <Box width={theme.spacing(4)} />
          <Button
            variant="contained"
            className="compact"
            fullWidth
            component={Link}
            to={getActivationPath(
              `${podKeyOrSerialNumber}/troubleshoot-battery`
            )}
          >
            {"There’s no light"}
          </Button>
        </Box>
      </Box>
    </ActivationLayout>
  );
}
